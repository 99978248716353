import React from 'react';
import Layout from '../../components/Layout';
import ServicePage from '../../components/ServicePage';

function roofing_siding_gutters({location}) {
    return (
        <div>
            <Layout pageName={location.pathname}>
                <ServicePage serviceNumber={8}/>
            </Layout>
        </div>
    )
}

export default roofing_siding_gutters
